h1, h2, h3, h4 {
/*    color: rgb(37, 124, 225); */
/*    color: rgb(128, 3, 0); */
    color: rgb(175, 5, 5);
}
.ui.header {
    /* color: rgb(37, 124, 225); */
    color: rgb(175, 5, 5);
}

.ptext {
    font-family: Helvetica Neue,Arial,Helvetica,sans-serif;
    font-size: large;
}

body {
    font-family: Helvetica Neue,Arial,Helvetica,sans-serif;
}

/* define top and bottom margins for a Container */
.topbotmargin {
    margin-top: 20px;
    margin-bottom: 20px;
}

#feedback_bottom {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 1000000
}

#feedback_vert {
    transform: rotate(90deg) translateX(38%);
    position: fixed;
    bottom: 50%;
    right: -35px;
}

span.avoid_wrap {
    display:inline-block;
}
