.ui.container.footerbanner {
    /* background-color: rgb(0, 46, 93); */
    background-color: #062e5d;
    /* position: absolute; */
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 200px;
    padding: 60px;
}

.ui.container.footerbanner img {
    margin: auto;
}
