@media only screen and (max-width: 767px) {
    #bannercontainer>img {
        max-height: 180px;
        margin: auto;
    }

    #bannercontainer {
        padding-top: 0px !important;
    }
}

.outercontainer {
    position: relative;
}

.small {
    height: 250px;
}

.large {
}

#innerbox {
    background: rgb(222,236,254);
    background: linear-gradient(180deg, rgba(222,236,254,1) 0%, rgba(222,236,254,1) 80%, rgba(211,231,253,1) 80%, rgba(211,231,253,1) 100%);
    padding-bottom: 1%;
}
