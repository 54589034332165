@media screen {
	  .noshow
    {
        display: none;
	  }
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .pagebreak {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
