.ui.container.copyrightbanner {
    background-color: #292929;
    color: #9e9e9e;
    width: 100%;
    padding-top: 10px;
}

.single {
    padding-bottom: 10px;
}

.double {
    padding-bottom: 50px;
}

.copyrightlink {
    color: #9e9e9e;
}
